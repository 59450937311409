import React from 'react';

const Loader = () => {
  return (
    <div className="loading">
      Loading...
    </div>
  );
};

export default Loader;