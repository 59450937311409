export const GET_CATEGORY = "GET_CATEGORY";
export const GET_PRODUCT = "GET_PRODUCT";
export const GET_POST = "GET_POST";
export const GET_BLOGS = "GET_BLOGS";
export const GET_SINGLE_BLOG = "GET_SINGLE_BLOG"
export const GET_POST_CATEGORIES = "GET_POST_CATEGORIES";
export const GET_POST_BY_CATEGORY = 'GET_POST_BY_CATEGORY';
export const CREATE_CONTACT_US = "CREATE_CONTACT_US";
export const GET_SINGLE_SHOP = "GET_SINGLE_SHOP";
export const GET_RELATED_PRODUCT = "GET_RELATED_PRODUCT"
export const CREATE_PRODUCT_INQUIRY = 'CREATE_PRODUCT_INQUIRY';
export const CREATE_BLOG_COMMENT = "CREATE_BLOG_COMMENT";
export const GET_CATEGORY_PRODUCT = "GET_CATEGORY_PRODUCT";
export const ADD_WHISHLIST_DATA = "ADD_WHISHLIST_DATA";
export const NAV_CLOSE = "NAV_CLOSE";
export const GET_SUBCATEGORY = "GET_SUBCATEGORY";
export const ADD_COMPARE = "ADD_COMPARE";
export const GET_PRODUCT_FILTER = "GET_PRODUCT_FILTER";
export const SIGN_IN_USER = "SIGN_IN_USER";
export const USER_DETAILS = 'USER_DETAILS';
export const USER_ORDERS = 'USER_ORDERS';
export const ADDTOCART = 'ADDTOCART';
export const CREATE_BOOK_APPOINMENT = "CREATE_BOOK_APPOINMENT";
export const LOADER = 'LOADER';
export const INSTAGRAMPOST = 'INSTAGRAMPOST';
export const CURRENCY_CONVERT = 'CURRENCY_CONVERT';
export const CURRENCY = 'CURRENCY';
export const GET_POST_BY_COMMENTS = 'GET_POST_BY_COMMENTS';
export const GET_PRODUCT_BY_SEARCH = 'GET_PRODUCT_BY_SEARCH';
