import { useDispatch, useSelector } from "react-redux";
import Header from "./header";
import Footer from "./footer";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

function isEmpty(val) {
    return (val === undefined || val == null || val.length <= 0) ? true : false;
  }

const Layout = ({children}) => {
    const disptach = useDispatch();
    const categoryList = useSelector((state) => state.home.categoryList);
    const userDetails = useSelector((state) => state.home.userDetails);
    const location=useLocation();
    const [userId, setUserId] = useState("");
    
    useEffect(() => {
        let userIds = sessionStorage.getItem("userId");
        if (!isEmpty(userIds) || !isEmpty(userDetails?.user_id)) {
            setUserId(userIds || userDetails?.user_id);
        }
    }, [disptach]);

    return (
        <>
            {(userId && location?.pathname == '/dashboard') ? " " : <Header categoryList={categoryList} />}
            <main>{children}</main>
            {(userId && location?.pathname == '/dashboard')  ? "" :<Footer categoryList={categoryList} />}
        </>
    )
}

export default Layout;