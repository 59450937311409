// import "./App.css";
// import {
//   Route,
//   Routes,
// } from "react-router-dom";
// // import Home from "./Pages/Home";
// import About from "./Pages/About";
// import Blog from "./Pages/Blog";
// import BlogDetail from "./Pages/Blog-detail";
// import ShopDetail from "./Pages/Shop-detail";
// import Contact from "./Pages/Contact-us";
// import TermsCondition from "./Pages/TermsCondition";
// import PrivacyPolicy from "./Pages/PrivacyPolicy";
// import ShippingPolicy from "./Pages/ShippingPolicy";
// // import Login from "./Pages/login";
// import ReturnPolicy from "./Pages/ReturnPolicy";
// import Shopping from "./Pages/shop-after-login";
// import Product from "./Pages/Shop";
// import WhishList from "./Pages/WhishList";
// import AddToCart from "./Pages/AddToCart";
// import { useDispatch, useSelector } from "react-redux";
// import { lazy, Suspense, useEffect, useState } from "react";
// import ShoppingByCategory from "./Pages/shop-after-login-category";
// import Clientportal from "./Clientportal";
// import "react-toastify/dist/ReactToastify.css";
// import { ToastContainer } from "react-toastify";
// import Register from "./Pages/Register";
// import BookAppoinment from "./Pages/BookAppoinment";
// import ScrollToTop from "./Pages/ScrollToTop";
// import ScrollToTopButton from "./Component/ScrolltoTop";
// import Loader from "./Pages/Loader";
// import Layout from "./Component/Layout";
// import PrivateRoute from "./Component/PrivateRoute";
// import BlogByCategory from "./Pages/BlogByCategory";
// import 'react-lazy-load-image-component/src/effects/blur.css';
import React, { lazy, Suspense, useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./Component/header";
import Footer from "./Component/footer";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import ScrollToTop from "./Pages/ScrollToTop";
import ScrollToTopButton from "./Component/ScrolltoTop";
import Loader from "./Pages/Loader";
import Layout from "./Component/Layout";
import Protected from "./Component/Protected";
import PrivateRoute from "./Component/PrivateRoute";
import 'react-toastify/dist/ReactToastify.css';
import 'react-lazy-load-image-component/src/effects/blur.css';


const Home = lazy(() => import('./Pages/Home'));
const About = lazy(() => import('./Pages/About'));
const Blog = lazy(() => import('./Pages/Blog'));
const BlogDetail = lazy(() => import('./Pages/Blog-detail'));
const ShopDetail = lazy(() => import('./Pages/Shop-detail'));
const Contact = lazy(() => import('./Pages/Contact-us'));
const TermsCondition = lazy(() => import('./Pages/TermsCondition'));
const PrivacyPolicy = lazy(() => import('./Pages/PrivacyPolicy'));
const ShippingPolicy = lazy(() => import('./Pages/ShippingPolicy'));
const Login = lazy(() => import('./Pages/login'));
const ReturnPolicy = lazy(() => import('./Pages/ReturnPolicy'));
const Shopping = lazy(() => import('./Pages/shop-after-login'));
const Product = lazy(() => import('./Pages/Shop'));
const WhishList = lazy(() => import('./Pages/WhishList'));
const AddToCart = lazy(() => import('./Pages/AddToCart'));
const ShoppingByCategory = lazy(() => import('./Pages/shop-after-login-category'));
const Clientportal = lazy(() => import('./Clientportal'));
const Register = lazy(() => import('./Pages/Register'));
const BookAppoinment = lazy(() => import('./Pages/BookAppoinment'));
const BlogByCategory = lazy(() => import('./Pages/BlogByCategory'));


function App() {
  const disptach = useDispatch();
  const loader = useSelector((state) => state.home.loader);
  const userDetails = useSelector((state) => state.home.userDetails);
  const [userId, setUserId] = useState("");

  function isEmpty(val) {
    return (val === undefined || val == null || val.length <= 0) ? true : false;
  }

  useEffect(() => {
    let userIds = sessionStorage.getItem("userId");
    if (!isEmpty(userIds) || !isEmpty(userDetails?.user_id)) {
      setUserId(userIds || userDetails?.user_id);
    }
  }, [disptach]);


  return (
    <div className="App">
      <Layout>
        <ToastContainer />
        {loader && (<Loader />)}
        <ScrollToTop />
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/dashboard"
              element={
                <PrivateRoute>
                  <Clientportal />
                </PrivateRoute>
              } />
            {<Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />}
            <Route path="/login" element={<Login />} />
            {<Route path="/about-us" element={<About />} />}
            {<Route path="/blog" element={<BlogDetail />} />}
            {<Route path="/blog/:id" element={<BlogByCategory />} />}
            {<Route path="/product" element={<Product />} />}
            <Route
              path="/shop/:id/:category_name/:category_id/:main_cateogry_name"
              element={<Shopping />}
            />
            <Route
              path="/shop/:category_id/:main_cateogry_name"
              element={<Shopping />}
            />
            <Route
              path="/shops/:category_id/:main_cateogry_name"
              element={<Shopping />}
            />
            <Route
              path="/shop-category/:id/:category_name"
              element={<ShoppingByCategory />}
            />
            <Route path="/blog-detail" element={<Blog />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route
              path="/shop-detail/:id/:category_name"
              element={<ShopDetail />}
            />
            <Route path="/shop-detail/:id" element={<ShopDetail />} />
            <Route path="/terms-condition" element={<TermsCondition />} />

            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/shipping-policy" element={<ShippingPolicy />} />
            <Route path="/return-policy" element={<ReturnPolicy />} />
            <Route path="/whish-list" element={<WhishList />} />
            <Route path="/addto-cart" element={<AddToCart />} />
            <Route path="/register" element={<Register />} />
            <Route path="/book-appointment" element={<BookAppoinment />} />
          </Routes>
        </Suspense>
        <ScrollToTopButton />
      </Layout>
    </div>
  );
}

export default App;
