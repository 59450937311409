// components/PrivateRoute.js

import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';


function isEmpty(val) {
    return (val === undefined || val == null || val.length <= 0) ? true : false;
}

const PrivateRoute = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const isAuthenticated = sessionStorage.getItem("userId");
        if (!isAuthenticated && isEmpty(isAuthenticated)) {
            navigate('/');
        } else if (!isEmpty(isAuthenticated) && location.pathname === "/") {
            navigate('/dashboard');
        }
    }, [navigate, location.pathname]);

    return <>{children}</>;
};

export default PrivateRoute;

